import React, {useContext} from "react";
import LanguageContext from '../../components/LanguageContext';
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Table from "./TableStructuralHollowSections.js";

const TableStructuralHollowSections = ({ children }) => {
    const { language } = useContext(LanguageContext);
  
    const menuTitles = {
      en: {
        header: "Structural Hollow Sections",

        phrase_1: "We supply round, square, and rectangular structural hollow sections manufactured according to the EN10219 standard.",
        phrase_2: "The products always come with a material certificate 3.1. according to EN 10204, CE marking. Pipes are hot-dip galvanizable.",
        phrase_3: "Structural hollow section lengths up to 18 meters, with standard lengths of 6.0 or 12.0 meters.",       
        phrase_5: "For other grades and details, please contact our sales team. You can submit your inquiries on our ",
        next_5: "contacts page."
      },
      fi: {
        header: "Putkipalkit",

        phrase_1: "Toimitamme pyöreitä, neliö- ja suorakaideputkipalkkeja, jotka ovat valmistettu EN10219 standardin mukaisesti.",
        phrase_2: "Tuotteilla on aina ainestodistus 3.1. EN 10204 mukaan, CE-merkintä. Putket ovat kuumasinkittäviä.",
        phrase_3: "Putkipalkkien pituudet aina 18 metriin saakka, standardipituudet 6,0 tai 12,0 metriä.",
        phrase_5: "Mikäli tarvitsette muita teräslaatuja ja/tai kokoja, ota yhteyttä myyntiimme. Voit lähettää kyselysi ",
        next_5: "yhteydenottosivulta."
      }
    };
    return (
        <Container className="container">
            <Row className="align-items-center d-flex justify-content-center">
                <Col xs={11} sm={11} md={11} className="mt-3">         
                    <h2>{menuTitles[language].header}</h2>
                    <p className="mt-3">{menuTitles[language].phrase_1}</p>
                    <p className="mt-3">{menuTitles[language].phrase_2}</p>
                    <p className="mt-3">{menuTitles[language].phrase_3}</p>
                </Col>
                <Col xs={11} sm={11} md={11} className="mt-3">
                    <Table />
                </Col>
                <Col xs={11} sm={11} md={11} className="mb-5">
                    <p className="mt-3 mb-3">{menuTitles[language].phrase_5}<NavLink to="/contacts_us">{menuTitles[language].next_5}</NavLink></p>
                </Col>
            </Row>
        </Container>
    )
}

export default TableStructuralHollowSections;


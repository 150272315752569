import React, {useContext} from "react";
import LanguageContext from '../../components/LanguageContext';

const Table = ({ children }) => {
  const { language } = useContext(LanguageContext);

  const menuTitles = {
    en: {
      thead_1: "Steel Grade",
      thead_2: "Width, up to, mm",
      thead_3: "Thickness range, mm",
      thead_4: "Manufacturing Standard"

    },
    fi: {
      thead_1: "Teräslaatu",
      thead_2: "Leveys, max., mm",
      thead_3: "Paksuus, välillä, mm",
      thead_4: "Tuotestandardi"
    }
  };
  
  return (
    <div style={{ width: '100%', overflowX: 'auto' }}>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th scope="col">{menuTitles[language].thead_1}</th>
            <th scope="col">{menuTitles[language].thead_2}</th>
            <th scope="col">{menuTitles[language].thead_3}</th>
            <th scope="col">{menuTitles[language].thead_4}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>S235JR, J2</td><td>2000</td><td>1,50 - 16,00</td><td>EN 10025</td>
          </tr>
          <tr>
            <td>S275JR, J2</td><td>2000</td><td>1,50 - 16,00</td><td>EN 10025</td>
          </tr>
          <tr>
            <td>S355JR, J2, K2</td><td>2000</td><td>1,50 - 16,00</td><td>EN 10025</td>
          </tr>
          <tr>
            <td>DD11</td><td>2000</td><td>1,50 - 16,00</td><td>EN 10111</td>
          </tr>
          <tr>
            <td>DD12</td><td>2000</td><td>1,50 - 16,00</td><td>EN 10111</td>
          </tr>
          <tr>
            <td>DD13</td><td>2000</td><td>1,50 - 16,00</td><td>EN 10111</td>
          </tr>
          <tr>
            <td>DD14</td><td>2000</td><td>1,50 - 16,00</td><td>EN 10111</td>
          </tr>
          <tr>
            <td>S355MC</td><td>2000</td><td>1,50 - 16,00</td><td>EN 10149</td>
          </tr>
          <tr>
            <td>S420MC</td><td>2000</td><td>1,50 - 16,00</td><td>EN 10149</td>
          </tr>
          <tr>
            <td>S460MC</td><td>2000</td><td>1,50 - 16,00</td><td>EN 10149</td>
          </tr>
          <tr>
            <td>S500MC</td><td>2000</td><td>1,50 - 16,00</td><td>EN 10149</td>
          </tr>
          <tr>
            <td>S600MC</td><td>2000</td><td>1,50 - 16,00</td><td>EN 10149</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Table;
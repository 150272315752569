import React, {useContext} from "react";
import { NavLink } from "react-bootstrap";
import LanguageContext from './LanguageContext';
import { Row, Col } from "react-bootstrap";
import '../css/Bottom.css';
import BottomImg from "../img/borders.png";
import EngLogo from '../img/luotettava/reliable_partner_logo_green.jpg';
import FinLogo from '../img/luotettava/luotettava_kumppani_logo_792x393.png';

const Bottom = ({ children }) => {
  const { language } = useContext(LanguageContext);

  const getFlagImage = () => {
    return language === 'fi' ? FinLogo : EngLogo;
  };

  const menuTitles = {
    en: {
      phrase_1: "Please ",
      phrase_2: "contact us, ",
      phrase_3: "our team will be pleased to assist you.",
      phrase_4: "Oy Listech Ltd"
    },
    fi: {
      phrase_1: "",
      phrase_2: "Ota yhteyttä, ",
      phrase_3: "yrityksemme auttaa sinua mielellään.",
      phrase_4: "Oy Listech Ltd"
    }
  };

  return (
    <Row>
        <img src={BottomImg} className="bottom-image" alt="Bottom" />
        <Col  xs={12} sm={12} md={5} className="text-center grey-back">
          <p className="small grey-back text-center mb-0"><br/> </p>
          <a href={language === 'fi' ? "https://zeckit.com/selvitys/FI/0655952-6?lang=fi" : "https://zeckit.com/selvitys/FI/0655952-6?lang=en"} rel="noopener" target="_blank">
              <img 
                src={getFlagImage()} 
                alt={language === 'fi' ? "FinLogo" : "EngLogo"} 
                className="flag-image" 
              />
          </a>
        </Col>
        <Col  xs={12} sm={12} md={7} className="text-center grey-back">
          <p className="small grey-back text-center mb-0"><br/> </p>
          <p className="small grey-back text-center mb-0">{menuTitles[language].phrase_1}<NavLink to="/contacts_us" className="contact-link">{menuTitles[language].phrase_2}</NavLink>{menuTitles[language].phrase_3}</p>
          <p className="small grey-back text-center mb-0"> {menuTitles[language].phrase_4}</p>
        </Col>
          <p className="small grey-back text-center mb-0"><br/> </p>
    </Row>
  )
}

export default Bottom;
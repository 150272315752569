import React, { useContext } from "react";
import LanguageContext from '../components/LanguageContext';
import { Container, Card, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const ProductCol = ({ linkTo, title }) => (
    <Col xs={12} md={6} className="mb-3">
        <Card bg="light">
            <NavLink to={linkTo} className="text-decoration-none">
                <Row noGutters className="align-items-center">
                    <Col>
                        <Card.Body className="text-center">
                            <Card.Title className="text-dark">{title}</Card.Title>
                        </Card.Body>
                    </Col>
                </Row>
            </NavLink>
        </Card>
    </Col>
);

const Products = ({ children }) => {
    const { language } = useContext(LanguageContext);

    const menuTitles = {
        en: {
            products_1: "Hot Rolled Steel",
            products_2: "Cold Rolled Steel",
            products_3: "Hot Dip Galvanized Steel",
            products_4: "Heavy Plates",
            products_5: "Structural Hollow Sections",
            products_6: "Spirally welded pipes SAWH",
            products_7: "Pre-painted steel material",
            products_8: "Other steel products",
            products_9: "Steel pre-manufacturing services",
            products_10: "Bio-fuels, charcoal and bio-char"
        },
        fi: {
            products_1: "Kuumavalssatut teräkset",
            products_2: "Kylmävalssatut teräkset",
            products_3: "Kuumasinkityt teräkset",
            products_4: "Kvarttolevyt",
            products_5: "Putkipalkit",
            products_6: "Kierresaumatut putket SAWH",
            products_7: "Maalipinnoitetut teräkset",
            products_8: "Muut terästuotteet",
            products_9: "Teräksen esikäsittelypalvelut",
            products_10: "Biopolttoaineet, puu- ja biohiili"
        }
    };

    return (
        <Container className="container">
            <Row className="align-items-center d-flex justify-content-center mt-2">
                <ProductCol linkTo="/products/hot-rolled_strip_steels" title={menuTitles[language].products_1} />
                <ProductCol linkTo="/products/cold_rolled_flat_steel" title={menuTitles[language].products_2} />
                <ProductCol linkTo="/products/hot_dip_galvanized_steel" title={menuTitles[language].products_3} />
                <ProductCol linkTo="/products/quatro_plates" title={menuTitles[language].products_4} />
                <ProductCol linkTo="/products/structural_hollow_sections" title={menuTitles[language].products_5} />
                <ProductCol linkTo="/products/helically_welded_pipes_SAWH" title={menuTitles[language].products_6} />
                <ProductCol linkTo="/products/pre-painted_steel_material" title={menuTitles[language].products_7} />
                <ProductCol linkTo="/products/other_steel_products" title={menuTitles[language].products_8} />
                <ProductCol linkTo="/products/steel_pre-manufacturing_services" title={menuTitles[language].products_9} />
                <ProductCol linkTo="/products/bio-fuels,charcaol_and_bio-char" title={menuTitles[language].products_10} />
            </Row>
        </Container>
    );
};

export default Products;
import React, {useContext} from "react";
import LanguageContext from '../components/LanguageContext';
import { Container, Row, Col } from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel';
import project from "../img/about/1project.jpg";
import office from "../img/about/1office.jpg";
import delivery from "../img/about/1delivery.jpg";

const About = ({ children }) => {
    const { language } = useContext(LanguageContext);

    const menuTitles = {
        en: {
            paragraph_1: 'Oy Listech Ltd is a flexible and dynamic steel trading house offering spot and long-term trade in industrial materials and specifically a large variety of steel products.',
            paragraph_2: 'We have our own network of steel producers, stockholders and steel service centers and operate on the basis of long-term relationships with our suppliers. This allows us to provide tailor-made solutions to our customers and cover all aspects of the delivery chain:',
            list:   ['Just-in-time deliveries',
                    'Regular monthly, quarterly, bi-annual and annual deliveries',
                    'Project deliveries'],
            paragraph_3: 'With the help of our global network of logistics experts we offer competitive transportation solutions by land and sea as well as their combination.'
        },
        fi: {
            paragraph_1: 'Oy Listech Ltd on joustava ja asiakaslähtöinen litteiden ja pitkien terästuotteiden spot- ja pitkäaikaissopimusmyyjä.',
            paragraph_2: 'Oy Listechin Ltd:n yhteistyöverkostossa on terästuottajia, tukkuliikkeitä ja teräspalvelukeskuksia. Tavaran toimittajiimme meillä on pitkät yhteistyösuhteet. Näin pystymme turvaamaan räätälöidyt ratkaisut asiakkaillemme ja huolehtimaan koko toimitusketjusta:',
            list:   ['Just-in-time -toimitukset',
                    'Säännölliset toimitukset kuukausittain ja vuosittain',
                    'Projektitoimitukset'],
            paragraph_3: ' Kansainvälisen logistiikkaverkostomme ansiosta tarjoamme kilpailukykyisen kuljetusratkaisun maalla ja merellä tai niiden yhdistelmänä.'
        }
    };

    return (
        <Container className="container">
            <Row className="align-items-center d-flex justify-content-center">
                <Col  xs={11} sm={11} md={5} className="mb-5">
                    <Carousel>
                        <Carousel.Item>
                            <img 
                                className="d-block w-100" 
                                src={project} 
                                alt="Project" 
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img 
                                className="d-block w-100" 
                                src={office} 
                                alt="Office" 
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100" 
                                src={delivery} 
                                alt="Delivery" 
                            />
                        </Carousel.Item>
                    </Carousel>
                </Col>
                <Col className="mb-5" xs={11} sm={11} md={7}>
                    <p>{menuTitles[language].paragraph_1}</p>
                    <br/>
                    <p>{menuTitles[language].paragraph_2}</p>
                    <br/>
                    <ul>
                        <li>{menuTitles[language].list[0]}</li>
                        <li>{menuTitles[language].list[1]}</li>
                        <li>{menuTitles[language].list[2]}</li>
                    </ul>
                    <br/>
                    <p>{menuTitles[language].paragraph_3}</p>
                    <br/>
                </Col>
            </Row>
        </Container>
    )
}

export default About;
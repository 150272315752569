import React, {useContext} from "react";
import LanguageContext from '../../components/LanguageContext';
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Table from "./TablePre-paintedSteelMaterial";

const PrePaintedSteelMaterial = ({ children }) => {
  const { language } = useContext(LanguageContext);

  const menuTitles = {
    en: {
      header: "Pre-painted steel material",
      phrase_1: "Pre Painted Galvanized coils for roofing and other applications are manufactured in accordance with EN standards. Mostly produced RAL colours include 1014, 1015, 3003, 3005, 3011, 5002, 5021, 6002, 6005, 7004, 8017, 9002, 9003.",
      phrase_2: "Other colours are also available",
      phrase_3: "Amongst others we currently deliver:",
      phrase_4: "For other grades and details, please contact our sales team. You can submit your inquiries on our ",
      next_4: "contacts page."
    },
    fi: {
      header: "Maalipinnoitetut teräkset",
      phrase_1: "EN-standardien mukaiset maalipinnoitetut teräskelat kattoihin ja muihin tarkoituksiin. Tavallisimmat värit (RAL): 1014, 1015, 3003, 3005, 3011, 5002, 5021, 6002, 6005, 7004, 8017, 9002, 9003.",
      phrase_2: "Myös muut värit mahdollisia.",
      phrase_3: "Toimitamme mm.:",
      phrase_4: "Mikäli tarvitsette muita teräslaatuja ja/tai kokoja, ota yhteyttä myyntiimme. Voit lähettää kyselysi ",
      next_4: "yhteydenottosivulta."
    }
  };
  
  return (
    <Container className="container">
      <Row className="align-items-center d-flex justify-content-center">
        <Col xs={11} sm={11} md={11} className="mt-3">
            <h2>{menuTitles[language].header}</h2>
            <p className="mt-3">{menuTitles[language].phrase_1}</p>
            <p className="mt-3">{menuTitles[language].phrase_2}</p>
            <p className="mt-3">{menuTitles[language].phrase_3}</p>
            </Col>
        <Col xs={11} sm={11} md={11} className="mt-3">
          <Table />
        </Col>
        <Col xs={11} sm={11} md={11} className="mb-5">
          <p className="mt-3 mb-3">{menuTitles[language].phrase_4}<NavLink to="/contacts_us">{menuTitles[language].next_4}</NavLink></p>
        </Col>
      </Row>
    </Container>
    )
}

export default PrePaintedSteelMaterial;
import React, { useContext } from "react";
import LanguageContext from '../components/LanguageContext';
import { Container, Card, Row, Col } from "react-bootstrap";
import Mail from "../img/contact_us/gmail.png";
import WhatsApp from "../img/contact_us/whatsapp.png";
import Telegram from "../img/contact_us/telegram.png";
import LinkedIn from "../img/contact_us/linkedin.png";

const LinkColLinkedIn = ({ title, imageUrl }) => {
    const linkedInLink = "https://www.linkedin.com/company/oy-listech-ltd/"; 

    return (
        <Col xs={12} md={6} className="mb-3">
            <Card bg="light">
                <a href={linkedInLink} className="text-decoration-none" target="_blank" rel="noopener noreferrer">
                    <Row noGutters className="align-items-center">
                        <Col xs={4} md={4}>
                            <Card.Img src={imageUrl} alt={title} />
                        </Col>
                        <Col xs={8} md={8}>
                            <Card.Body className="text-center">
                                <Card.Title className="text-dark">{title}</Card.Title>
                            </Card.Body>
                        </Col>
                    </Row>
                </a>
            </Card>
        </Col>
    );
};


const LinkColTelegram = ({ title, imageUrl }) => {
    const telegramLink = `https://t.me/+358415220353`;

    return (
        <Col xs={12} md={6} className="mb-3">
            <Card bg="light">
                <a href={telegramLink} className="text-decoration-none" target="_blank" rel="noopener noreferrer">
                    <Row noGutters className="align-items-center">
                        <Col xs={4} md={4}>
                            <Card.Img src={imageUrl} alt={title} />
                        </Col>
                        <Col xs={8} md={8}>
                            <Card.Body className="text-center">
                                <Card.Title className="text-dark">{title}</Card.Title>
                            </Card.Body>
                        </Col>
                    </Row>
                </a>
            </Card>
        </Col>
    );
};


const LinkColWhatsApp = ({ title, imageUrl }) => {
    const formattedMessage = encodeURIComponent('Hello'); //можно вести начальный текст
    const whatsappLink = `https://wa.me/+358415220353?text=${formattedMessage}`; // Заменить на номер
    return (
        <Col xs={12} md={6} className="mb-3">
            <Card bg="light">
                <a href={whatsappLink} className="text-decoration-none" target="_blank" rel="noopener noreferrer">
                    <Row noGutters className="align-items-center">
                        <Col  xs={4} md={4}>
                            <Card.Img src={imageUrl} alt={title} />
                        </Col>
                        <Col xs={8} md={8}>
                            <Card.Body className="text-center">
                                <Card.Title className="text-dark">{title}</Card.Title>
                            </Card.Body>
                        </Col>
                    </Row>
                </a>
            </Card>
        </Col>
    );
};

const LinkColMail = ({ title, imageUrl }) => (
    <Col xs={12} md={6} className="mb-3">
        <Card bg="light">
            <a href={`mailto:listech@listech.fi`} className="text-decoration-none">
                <Row noGutters className="align-items-center">
                    <Col  xs={4} md={4}>
                        <Card.Img src={imageUrl} alt={title} />
                    </Col>
                    <Col xs={8} md={8}>
                        <Card.Body className="text-center">
                            <Card.Title className="text-dark">{title}</Card.Title>
                        </Card.Body>
                    </Col>
                </Row>
            </a>
        </Card>
    </Col>
);

const Products = ({ children }) => {
    const { language } = useContext(LanguageContext);

    const menuTitles = {
        en: {
            contact_1: "LinkedIn",
            contact_2: "Mail",
            contact_3: "Telegram",
            contact_4: "WhatsApp"
        },
        fi: {
            contact_1: "LinkedIn",
            contact_2: "Mail",
            contact_3: "Telegram",
            contact_4: "WhatsApp"
        }
    };

    return (
        <Container className="container">
            <Row className="align-items-center d-flex justify-content-center mt-2">
                <LinkColMail title={menuTitles[language].contact_2} imageUrl={Mail} />
                <LinkColWhatsApp title={menuTitles[language].contact_4} imageUrl={WhatsApp} />
                <LinkColLinkedIn title={menuTitles[language].contact_1} imageUrl={LinkedIn} />
                <LinkColTelegram title={menuTitles[language].contact_3} imageUrl={Telegram} />
            </Row>
        </Container>
    );
};

export default Products;
import React from "react";
import { Carousel } from "react-bootstrap";
import Product1 from "../img/news/angles.jpg";
import Product2 from "../img/news/bars1.jpg";
import Product3 from "../img/news/beams.jpg";
import Product4 from "../img/news/billets.jpg";
import Product5 from "../img/news/billets1.jpg";
import Product6 from "../img/news/rebars.jpg";

const CarouselNews = ({ items }) => (
    <Carousel>
        <Carousel.Item>
            <img 
                className="d-block w-100 img-fluid" 
                src={Product1} 
                alt="Product1" 
            />
        </Carousel.Item>
        <Carousel.Item>
            <img
                className="d-block w-100 img-fluid" 
                src={Product2} 
                alt="Product2"
            />
        </Carousel.Item>
        <Carousel.Item>
            <img 
                className="d-block w-100 img-fluid" 
                src={Product3} 
                alt="Product3" 
            />
        </Carousel.Item>
        <Carousel.Item>
            <img
                className="d-block w-100 img-fluid" 
                src={Product4}
                alt="Product4" 
            />
        </Carousel.Item>
        <Carousel.Item>
            <img 
                className="d-block w-100 img-fluid" 
                src={Product5} 
                alt="Product5"
            />
        </Carousel.Item>
        <Carousel.Item>
            <img
                className="d-block w-100 img-fluid" 
                src={Product6} 
                alt="Product6" 
            />
        </Carousel.Item>
    </Carousel>
);

export default CarouselNews;

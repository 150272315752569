import React, {useContext} from "react";
import LanguageContext from '../../components/LanguageContext';
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Table from "./TableHelicallyWeldedPipesSAWH";

const ColdRollerFlatSteel = ({ children }) => {
    const { language } = useContext(LanguageContext);

    const menuTitles = {
        en: {
        header: "Helically welded pipes SAWH",
        phrase_1: "Since the beginning of 2011, we have also supplied spiral welded pipes for various EU projects. The pipes are made of S355J2H steel (EN 10219). Lengths are 10.0-12.0 meters or according to customer requirements. The pipes meet very strict quality requirements and can be supplied with 3-layer FBE or PE inner and/or outer coating if necessary.",
        phrase_2: "The products always come with a material certificate 3.1. according to EN 10204, CE marking. Pipes are hot-dip galvanizable.",
        phrase_3: "For other grades and details, please contact our sales team. You can submit your inquiries on our ",
        next_3: "contacts page."
        },
        fi: {
        header: "Kierresaumatut putket SAWH",
        phrase_1: "Vuoden 2011 alusta olemme toimittaneet myös kierresaumattua putkea erilaisiin EU-projekteihin. Putket on valmistettu S355J2H teräksestä (EN 10219). Pituudet ovat 10,0-12,0 metriä tai asiakkaan tarpeen mukaan. Putket vastaavat erittäin tiukkoja laatuvaatimuksia ja ne voidaan tarvittaessa toimittaa 3-os. FBE or PE sisä- ja/tai ulkopinnoitteella.",
        phrase_2: "Tuotteilla on aina ainestodistus 3.1. EN 10204 mukaan, CE-merkintä. Putket kuumasinkittäviä.",
        phrase_3: "Mikäli tarvitsette muita teräslaatuja ja/tai kokoja, ota yhteyttä myyntiimme. Voit lähettää kyselysi ",
        next_3: "yhteydenottosivulta."
        }
    };
    
    return (
        <Container className="container">
            <Row className="align-items-center d-flex justify-content-center">
                <Col xs={11} sm={11} md={11} className="mt-3">
                    <h2>{menuTitles[language].header}</h2>       
                    <p className="mt-3">{menuTitles[language].phrase_1}</p>
                    <p className="mt-3">{menuTitles[language].phrase_2}</p>
                </Col>
                <Col xs={11} sm={11} md={11} className="mt-3">
                    <Table />
                </Col>
                <Col xs={11} sm={11} md={11} className="mb-5">
                    <p className="mt-3 mb-3">{menuTitles[language].phrase_3}<NavLink to="/contacts_us">{menuTitles[language].next_3}</NavLink></p>
                </Col>
            </Row>
        </Container>
    )
}

export default ColdRollerFlatSteel;
import React from "react";
import { Carousel } from "react-bootstrap";
import Product1 from "../img/bio/bio_1.jpeg";
import Product2 from "../img/bio/bio_2.jpeg";
import Product3 from "../img/bio/bio_3.jpeg";
import Product4 from "../img/bio/bio_4.jpeg";
import Product5 from "../img/bio/bio_5.jpeg";
import Product6 from "../img/bio/bio_6.jpeg";
import Product7 from "../img/bio/bio_7.jpeg";
import Product8 from "../img/bio/bio_8.jpeg";
import Product9 from "../img/bio/bio_9.jpeg";
import Product10 from "../img/bio/bio_10.jpeg";
import Product11 from "../img/bio/bio_11.jpeg";
import Product12 from "../img/bio/bio_12.jpeg";
import '../css/Carousel.css';

const CarouselBioFlues = ({ items }) => (
    <Carousel>
        <Carousel.Item>
            <img 
                className="d-block w-100 img-fluid carousel-img" 
                src={Product1} 
                alt="Product1" 
            />
        </Carousel.Item>
        <Carousel.Item>
            <img
                className="d-block w-100 img-fluid carousel-img" 
                src={Product2} 
                alt="Product2"
            />
        </Carousel.Item>
        <Carousel.Item>
            <img 
                className="d-block w-100 img-fluid carousel-img" 
                src={Product3} 
                alt="Product3" 
            />
        </Carousel.Item>
        <Carousel.Item>
            <img
                className="d-block w-100 img-fluid carousel-img" 
                src={Product4}
                alt="Product4" 
            />
        </Carousel.Item>
        <Carousel.Item>
            <img 
                className="d-block w-100 img-fluid carousel-img" 
                src={Product5} 
                alt="Product5"
            />
        </Carousel.Item>
        <Carousel.Item>
            <img
                className="d-block w-100 img-fluid carousel-img" 
                src={Product6} 
                alt="Product6" 
            />
        </Carousel.Item>
        <Carousel.Item>
            <img
                className="d-block w-100 img-fluid carousel-img" 
                src={Product7} 
                alt="Product7" 
            />
        </Carousel.Item>
        <Carousel.Item>
            <img
                className="d-block w-100 img-fluid carousel-img" 
                src={Product8} 
                alt="Product8" 
            />
        </Carousel.Item>
        <Carousel.Item>
            <img
                className="d-block w-100 img-fluid carousel-img" 
                src={Product9} 
                alt="Product9" 
            />
        </Carousel.Item>
        <Carousel.Item>
            <img
                className="d-block w-100 img-fluid carousel-img" 
                src={Product10} 
                alt="Product10" 
            />
        </Carousel.Item>
        <Carousel.Item>
            <img
                className="d-block w-100 img-fluid carousel-img" 
                src={Product11} 
                alt="Product11" 
            />
        </Carousel.Item>
        <Carousel.Item>
            <img
                className="d-block w-100 img-fluid carousel-img" 
                src={Product12} 
                alt="Product12" 
            />
        </Carousel.Item>
    </Carousel>
);

export default CarouselBioFlues;

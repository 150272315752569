import React, {useContext} from "react";
import LanguageContext from '../../components/LanguageContext';

const Table = ({ children }) => {
    const { language } = useContext(LanguageContext);
  
    const menuTitles = {
      en: {
        thead_1: "Round HS dimensions, mm",
        small_thead_1: "Diam.",
        small_thead_2: "S",
        small_thead_3: "Steel Grades",
        thead_2: "Square HS dimensions, mm",
        small_thead_4: "A x B",
        small_thead_5: "S",
        small_thead_6: "Steel Grades",
        thead_3: "Rectangular HS dimensions, mm",
        small_thead_7: "A x B",
        small_thead_8: "S",
        small_thead_9: "Steel Grades"
  
      },
      fi: {
        thead_1: "Pyöreät, mm",
        small_thead_1: "Halk.",
        small_thead_2: "S",
        small_thead_3: "Teräslaatu",
        thead_2: "Neliö, mm",
        small_thead_4: "A x B",
        small_thead_5: "S",
        small_thead_6: "Teräslaatu",
        thead_3: "Suorakaide, mm",
        small_thead_7: "A x B",
        small_thead_8: "S",
        small_thead_9: "Teräslaatu"
      }
    };
    
    return (
      <div style={{ width: '100%', overflowX: 'auto' }}>
        <table  className="table table-bordered">
          <thead>
                <tr>
                    <th colspan="3">{menuTitles[language].thead_1}</th>
                    <th colspan="3">{menuTitles[language].thead_2}</th>
                    <th colspan="3">{menuTitles[language].thead_3}</th>
                </tr>
          </thead>
          <tbody>
                <tr><td>{menuTitles[language].small_thead_1}</td><td>{menuTitles[language].small_thead_2}</td><td>{menuTitles[language].small_thead_3}</td><td>{menuTitles[language].small_thead_4}</td><td>{menuTitles[language].small_thead_5}</td><td>{menuTitles[language].small_thead_6}</td><td>{menuTitles[language].small_thead_7}</td><td>{menuTitles[language].small_thead_8}</td><td>{menuTitles[language].small_thead_9}</td></tr>
                <tr><td>146</td><td>4-8</td><td>S235-S460MH</td><td>40 x 40</td><td>3-6</td><td>S235-S460MH</td><td>100 x 50</td><td>3-5</td><td>S235-S460MH</td></tr>
 <tr><td>159</td><td>4-8</td><td>S235-S460MH</td><td>50 x 50</td><td>3-6</td><td>S235-S460MH</td><td>100 x 60</td><td>3-6</td><td>S235-S460MH</td></tr>
 <tr><td>168</td><td>4-8</td><td>S235-S460MH</td><td>60 x 60</td><td>3-6</td><td>S235-S460MH</td><td>120 x 80</td><td>3-7</td><td>S235-S460MH</td></tr>
 <tr><td>219</td><td>3-20</td><td>S235-S460MH</td><td>80 x 80</td><td>3-6</td><td>S235-S460MH</td><td>140 x 60</td><td>3-7</td><td>S235-S460MH</td></tr>
 <tr><td>219,1</td><td>3-20</td><td>S235-S460MH</td><td>100 x 100</td><td>3-7</td><td>S235-S460MH</td><td>140 x 100</td><td>4-7</td><td>S235-S460MH</td></tr>
 <tr><td>273</td><td>3-22</td><td>S235-S460MH</td><td>120 x 120</td><td>4-7</td><td>S235-S460MH</td><td>140 x 120</td><td>6,5-8</td><td>S235-S460MH</td></tr>
 <tr><td>273,1</td><td>3-22</td><td>S235-S460MH</td><td>140 x 140</td><td>4-8</td><td>S235-S460MH</td><td>150 x 50</td><td>3-7</td><td>S235-S460MH</td></tr>
 <tr><td>323,8</td><td>3,5-22</td><td>S235-S460MH</td><td>150 x 150</td><td>4-8</td><td>S235-S460MH</td><td>150 x 100</td><td>4-7</td><td>S235-S460MH</td></tr>
 <tr><td>325</td><td>3,5</td><td>S235-S460MH</td><td>160 x 160</td><td>4-8</td><td>S235-S460MH</td><td>150 x 130</td><td>4-8</td><td>S235-S460MH</td></tr>
 <tr><td>355,6</td><td>4-22</td><td>S235-S460MH</td><td>180 x 180</td><td>3-16</td><td>S235-S460MH</td><td>160 x 80</td><td>4-7</td><td>S235-S460MH</td></tr>
 <tr><td>377</td><td>4-22</td><td>S235-S460MH</td><td>200 x 200</td><td>3-16</td><td>S235-S460MH</td><td>160 x 120</td><td>4-8</td><td>S235-S460MH</td></tr>
 <tr><td>406,4</td><td>4-22</td><td>S235-S460MH</td><td>250 x 250</td><td>4-22</td><td>S235-S460MH</td><td>160 x 140</td><td>4-8</td><td>S235-S460MH</td></tr>
 <tr><td>426</td><td>4,5-22</td><td>S235-S460MH</td><td>300 x 300</td><td>4-22</td><td>S235-S460MH</td><td>180 x 60</td><td>4-7</td><td>S235-S460MH</td></tr>
 <tr><td>457</td><td>5,2-22</td><td>S235-S460MH</td><td>350 x 350</td><td>5-22</td><td>S235-S460MH</td><td>180 x 100</td><td>4-8</td><td>S235-S460MH</td></tr>
 <tr><td>508</td><td>5,2-22</td><td>S235-S460MH</td><td>400 x 400</td><td>6-22</td><td>S235-S460MH</td><td>180 x 120</td><td>4-8</td><td>S235-S460MH</td></tr>
 <tr><td>530</td><td>6-22</td><td>S235-S460MH</td><td>450 x 450</td><td>6-22</td><td>S235-S460MH</td><td>180 x 140</td><td>4-8</td><td>S235-S460MH</td></tr>
 <tr><td>530,2</td><td>6-22</td><td>S235-S460MH</td><td>500 x 500</td><td>7-22</td><td>S235-S460MH</td><td>200 x 100</td><td>4-8</td><td>S235-S460MH</td></tr>
 <tr><td>558</td><td>6-22</td><td>S235-S460MH</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>200 x 120</td><td>4-8</td><td>S235-S460MH</td></tr>
 <tr><td>558,8</td><td>6-22</td><td>S235-S460MH</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>200 x 150</td><td>5-7</td><td>S235-S460MH</td></tr>
 <tr><td>609,6</td><td>6,4-22</td><td>S235-S460MH</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>200 x 160</td><td>3-16</td><td>S235-S460MH</td></tr>
 <tr><td>610</td><td>6,3-22</td><td>S235-S460MH</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>240 x 120</td><td>3-16</td><td>S235-S460MH</td></tr>
 <tr><td>630</td><td>7-22</td><td>S235-S460MH</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>240 x 150</td><td>5-8</td><td>S235-S460MH</td></tr>
 <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>240 x 160</td><td>5-12</td><td>S235-S460MH</td></tr>
 <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>250 x 140</td><td>5-8</td><td>S235-S460MH</td></tr>
 <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>250 x 150</td><td>5-12</td><td>S235-S460MH</td></tr>
 <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>260 x 130</td><td>8-10</td><td>S235-S460MH</td></tr>
 <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>260 x 140</td><td>5-12</td><td>S235-S460MH</td></tr>
 <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>260 x 240</td><td>6-12</td><td>S235-S460MH</td></tr>
 <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>300 x 100</td><td>6-12</td><td>S235-S460MH</td></tr>
 <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>300 x 200</td><td>6-12</td><td>S235-S460MH</td></tr>
 <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>350 x 150</td><td>6-12</td><td>S235-S460MH</td></tr>
 <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>350 x 250</td><td>4-22</td><td>S235-S460MH</td></tr>
 <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>400 x 200</td><td>4-22</td><td>S235-S460MH</td></tr>
 <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>400 x 300</td><td>5-22</td><td>S235-S460MH</td></tr>
 <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>450 x 350</td><td>6-22</td><td>S235-S460MH</td></tr>
 <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>500 x 300</td><td>6-22</td><td>S235-S460MH</td></tr>
 <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>500 x 400</td><td>6-22</td><td>S235-S460MH</td></tr>

          </tbody>
        </table>
      </div>
    )
}

export default Table;

import React, {useContext} from "react";
import LanguageContext from '../../components/LanguageContext';

const Table = ({ children }) => {
    const { language } = useContext(LanguageContext);
  
    const menuTitles = {
      en: {
        thead_1: "Steel Grade",
        thead_2: "Zinc coating",
        thead_3: "Width, up to, mm",
        thead_4: "Thickness range, mm",
        thead_5: "Manufacturing Standard"
  
      },
      fi: {
        thead_1: "Teräslaatu",
        thead_2: "Pinnoite",
        thead_3: "Leveys, max., mm",
        thead_4: "Paksuus, välillä, mm",
        thead_5: "Tuotestandardi"
      }
    };

    return (   
      <div style={{ width: '100%', overflowX: 'auto' }}>
        <table className="table table-bordered">
            <thead>
                <tr>
                    <th scope="col">{menuTitles[language].thead_1}</th>
                    <th scope="col">{menuTitles[language].thead_2}</th>
                    <th scope="col">{menuTitles[language].thead_3}</th>
                    <th scope="col">{menuTitles[language].thead_4}</th>
                    <th scope="col">{menuTitles[language].thead_5}</th>
                </tr> 
            </thead>
            <tbody>
              <tr><td>DX 51 D</td><td>Z100-Z275</td><td>1500</td><td>0,45 - 2,00</td><td>EN 10142 / 10327</td></tr>
              <tr><td>DX 52 D</td><td>Z100-Z275</td><td>1500</td><td>0,45 - 2,00</td><td>EN 10142 / 10327</td></tr>
              <tr><td>DX 53 D</td><td>Z100-Z275</td><td>1500</td><td>0,45 - 2,00</td><td>EN 10142 / 10327</td></tr>
              <tr><td>DX 54 D</td><td>Z100-Z275</td><td>1500</td><td>0,45 - 2,00</td><td>EN 10142 / 10327</td></tr>
              <tr><td>DX 56 D</td><td>Z100-Z275</td><td>1500</td><td>0,45 - 2,00</td><td>EN 10142 / 10327</td></tr>
              <tr><td>DX 57 D</td><td>Z100-Z275</td><td>1500</td><td>0,45 - 2,00</td><td>EN 10142 / 10327</td></tr>
              <tr><td>H/HX180YD</td><td>Z100-Z275</td><td>1500</td><td>0,45 - 2,00</td><td>EN 10292</td></tr>
              <tr><td>H/HX220YD</td><td>Z100-Z275</td><td>1500</td><td>0,45 - 2,00</td><td>EN 10292</td></tr>
              <tr><td>H/HX260YD</td><td>Z100-Z275</td><td>1500</td><td>0,45 - 2,00</td><td>EN 10292</td></tr>
              <tr><td>H/HX220PD</td><td>Z100-Z275</td><td>1500</td><td>0,45 - 2,00</td><td>EN 10292</td></tr>
              <tr><td>H/HX260YD</td><td>Z100-Z275</td><td>1500</td><td>0,45 - 2,00</td><td>EN 10292</td></tr>
              <tr><td>H/HX300YD</td><td>Z100-Z275</td><td>1500</td><td>0,45 - 2,00</td><td>EN 10292</td></tr>
              <tr><td>H/HX180BD</td><td>Z100-Z275</td><td>1500</td><td>0,45 - 2,00</td><td>EN 10292</td></tr>
              <tr><td>H/HX220BD</td><td>Z100-Z275</td><td>1500</td><td>0,45 - 2,00</td><td>EN 10292</td></tr>
              <tr><td>H/HX260BD</td><td>Z100-Z275</td><td>1500</td><td>0,45 - 2,00</td><td>EN 10292</td></tr>
              <tr><td>H/HX300BD</td><td>Z100-Z275</td><td>1500</td><td>0,45 - 2,00</td><td>EN 10292</td></tr>
              <tr><td>H/HX260LAD</td><td>Z100-Z275</td><td>1500</td><td>0,45 - 2,00</td><td>EN 10292</td></tr>
              <tr><td>H/HX300LAD</td><td>Z100-Z275</td><td>1500</td><td>0,45 - 2,00</td><td>EN 10292</td></tr>
              <tr><td>H/HX340LAD</td><td>Z100-Z275</td><td>1500</td><td>0,45 - 2,00</td><td>EN 10292</td></tr>
              <tr><td>H/HX380LAD</td><td>Z100-Z275</td><td>1500</td><td>0,45 - 2,00</td><td>EN 10292</td></tr>
              <tr><td>H/HX420LAD</td><td>Z100-Z275</td><td>1500</td><td>0,45 - 2,00</td><td>EN 10292</td></tr>
              <tr><td>S220GD</td><td>Z100-Z275</td><td>1500</td><td>0,45 - 2,00</td><td>EN 10147 / 10326</td></tr>
              <tr><td>S250GD</td><td>Z100-Z275</td><td>1500</td><td>0,45 - 2,00</td><td>EN 10147 / 10326</td></tr>
              <tr><td>S280GD</td><td>Z100-Z275</td><td>1500</td><td>0,45 - 2,00</td><td>EN 10147 / 10326</td></tr>
              <tr><td>S320GD</td><td>Z100-Z275</td><td>1500</td><td>0,45 - 2,00</td><td>EN 10147 / 10326</td></tr>
              <tr><td>S350GD</td><td>Z100-Z275</td><td>1500</td><td>0,45 - 2,00</td><td>EN 10147 / 10326</td></tr>
            </tbody>
        </table>
      </div>
    )
}

export default Table;
import React, {useContext} from "react";
import LanguageContext from '../../components/LanguageContext';
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Table from "./TableQuatroPlates.js";

const QuatroPlates = ({ children }) => {
    const { language } = useContext(LanguageContext);
  
    const menuTitles = {
      en: {
        header: "Heavy Plates",

        phrase_1: "The stock plates are of quality S355K2+N and S355J2+N, with a thickness range of 8mm - 50mm and sizes of 2000x6000mm or 2450x6000mm. Steels of EU origin. We deliver heavy plates anywhere in Finland from our stock, with a delivery time of 2-3 days.",
        phrase_2: "The products always come with a material certificate 3.1. according to EN 10204, CE marking. Plates are hot-dip galvanizable.",
        phrase_3: "We also deliver heavy plates directly from the factory, allowing for other steel grades and dimensions, including special widths and lengths heavy plates up to a size of 3000x14000mm. Deliveries directly by trucks to Finland, other Nordic countries, and Central Europe.",
        phrase_4: "For other grades and details, please contact our sales team. You can submit your inquiries on our ",
        next_4: "contacts page."
      },
      fi: {
        header: "Kvarttolevyt",

        phrase_1: "Varastolevyt ovat laadussa S355K2+N ja S355J2+N, mitta-alue on 8mm - 50mm ja koko 2000x6000mm tai 2450x6000mm. Teräkset EU:n alkuperää. Toimitamme kvarttolevyt minne tahansa Suomeen varastostamme, 2-3 päivän toimitusajalla.",
        phrase_2: "Tuotteilla on aina ainestodistus 3.1. EN 10204 mukaan, CE-merkintä. Levyt ovat kuumasinkittäviä.",
        phrase_3: "Toimitamme kvarttolevyt myöskin suoraan tehtaalta, jolloin muutkin teräslaadut ja mitat ovat mahdollisia, myöskin erikoisleveitä ja pitkiä kvarttolevyjä mitassa 3000x14000mm saakka. Toimitukset suoraan autoilla Suomeen, muihin Pohjoismaihin ja Keski-Eurooppaan.",      
        phrase_4: "Mikäli tarvitsette muita teräslaatuja ja/tai kokoja, ota yhteyttä myyntiimme. Voit lähettää kyselysi ",
        next_4: "yhteydenottosivulta."
      }
    };

    return (
        <Container className="container">
            <Row className="align-items-center d-flex justify-content-center">
                <Col xs={11} sm={11} md={11} className="mt-3">         
                    <h2>{menuTitles[language].header}</h2>
                    <p className="mt-3 ">{menuTitles[language].phrase_1}</p>
                    <p className="mt-3">{menuTitles[language].phrase_2}</p>
                    <p className="mt-3">{menuTitles[language].phrase_3}</p>
                </Col>
                <Col xs={11} sm={11} md={11} className="mt-3">
                    <Table />
                </Col>
                <Col xs={11} sm={11} md={11} className="mb-5">
                    <p className="mt-3 mb-3">{menuTitles[language].phrase_4}<NavLink to="/contacts_us">{menuTitles[language].next_4}</NavLink></p>
                </Col>
            </Row>
        </Container>
        )
    }

export default QuatroPlates;

import React, {useContext} from "react";
import LanguageContext from '../../components/LanguageContext';
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const OtherSteelProducts = ({ children }) => {
    const { language } = useContext(LanguageContext);

    const menuTitles = {
        en: {
            header_1: 'Other steel products',
            phrase_1: 'We also supply the following flat and long steel products:',
            list: ['Billets and slabs', 'Reinforcing steels', 'UNP beams', 'UPE beams', 'INP beams', 'IPE beams', 'HEA beams', 'HEB beams', 'Quenched and tempered steels', 'Flat bars', 'Square bars', 'Angle bars', 'Precision tubes - round, square, and rectangular', 'Cold-formed U-profiles'],
            phrase_2: "For other grades and details, please contact our sales team. You can submit your inquiries on our ",
            next_2: "contacts page."
        },
        fi: {
            header_1: 'Muut terästuotteet',
            phrase_1: 'Toimitamme myös seuraavia litteitä ja pitkiä terästuotteita:',
            list: ['Aihiot ja slabit', 'Harjateräkset', 'UNP palkit', 'UPE palkit', 'INP palkit', 'IPE palkit', 'HEA palkit', 'HEB palkit', 'Nuorrutusteräkset', 'Lattateräkset', 'Neliöteräkset', 'Kulmateräkset', 'Tarkkuusputket - pyöreät, neliöt ja suorakaiteet', 'Kylmämuovatut U-profiilit'],
            phrase_2: "Mikäli tarvitsette muita teräslaatuja ja/tai kokoja, ota yhteyttä myyntiimme. Voit lähettää kyselysi ",
            next_2: "yhteydenottosivulta."
        }
    };

    return (
        <Container className="container">
            <Row className="align-items-center d-flex justify-content-center">
                <Col xs={11} sm={11} md={11} className="mt-3">
                    <h2>{menuTitles[language].header_1}</h2>
                    <p className="mt-3">{menuTitles[language].phrase_1}</p>
                    <Row>
                        <Col xs={6} md={3} className="mb-3">
                            <p style={{ fontStyle: 'italic' }}>
                                {menuTitles[language].list[0]}
                                <br/>{menuTitles[language].list[1]}
                                <br/>{menuTitles[language].list[2]}
                                <br/>{menuTitles[language].list[3]}
                            </p>
                        </Col>
                        <Col xs={6} md={3} className="mb-3">
                            <p style={{ fontStyle: 'italic' }}>
                                {menuTitles[language].list[4]}
                                <br/>{menuTitles[language].list[5]}
                                <br/>{menuTitles[language].list[6]}
                                <br/>{menuTitles[language].list[7]}
                            </p>
                        </Col>
                        <Col xs={6} md={3} className="mb-3">
                            <p style={{ fontStyle: 'italic' }}>
                                {menuTitles[language].list[8]}
                                <br/>{menuTitles[language].list[9]}
                                <br/>{menuTitles[language].list[10]}
                                <br/>{menuTitles[language].list[11]}
                            </p>
                        </Col>
                        <Col xs={6} md={3} className="mb-3">
                            <p style={{ fontStyle: 'italic' }}>
                                {menuTitles[language].list[12]}
                                <br/>{menuTitles[language].list[13]}
                            </p>
                        </Col>
                    </Row>
                </Col>
                <Col xs={11} sm={11} md={11} className="mb-5">
                    <p className="mt-3 mb-3">{menuTitles[language].phrase_2}<NavLink to="/contacts_us">{menuTitles[language].next_2}</NavLink></p>
                </Col>
            </Row>
        </Container>
    )
}

export default OtherSteelProducts;
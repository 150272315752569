import React, {useContext} from "react";
import LanguageContext from '../../components/LanguageContext';

const Table = ({ children }) => {
  const { language } = useContext(LanguageContext);

  const menuTitles = {
    en: {
      thead_1: "Steel Grade",
      thead_2: "Width, up to, mm",
      thead_3: "Thickness range, mm",
      thead_4: "Manufacturing Standard"

    },
    fi: {
      thead_1: "Teräslaatu",
      thead_2: "Leveys, max., mm",
      thead_3: "Paksuus, välillä, mm",
      thead_4: "Tuotestandardi"
    }
  };
  
  return (
    <div style={{ width: '100%', overflowX: 'auto' }}>
        <table  className="table table-bordered">
            <thead>
                <tr>
                    <th scope="col">{menuTitles[language].thead_1}</th>
                    <th scope="col">{menuTitles[language].thead_2}</th>
                    <th scope="col">{menuTitles[language].thead_3}</th>
                    <th scope="col">{menuTitles[language].thead_4}</th>
                </tr>
            </thead>
            <tbody>
                <tr><td>DX51D+Z - DX56D+Z</td><td>1650</td><td>0,40 - 1,20</td><td>EN 10142</td></tr>
                <tr><td>S220GD+Z - S280GD+Z</td><td>1650</td><td>0,40 - 1,20</td><td>EN 10147</td></tr>
                <tr><td>DC01 - DC04</td><td>1650</td><td>0,40 - 1,20</td><td>EN 10130</td></tr>
            </tbody>
        </table>
    </div>
  )
}

export default Table;
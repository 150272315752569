import React, {useContext} from "react";
import LanguageContext from '../components/LanguageContext';
import { Container, Row, Col } from "react-bootstrap";
import CarouselNews from "../components/CarouselNews.js";

const NewsTwo = ({ children }) => {
    const { language } = useContext(LanguageContext);

    const menuTitles = {
        en: {
            header_1: 'Oy Listech Ltd',
            phrase_1: 'Oy Listech Ltd was established in Helsinki in 1986. Over nearly 40 years, we have embarked on various projects including technology transfers, factory renovations, and the development of new products and international relations in Finland and the EU. During these years, our company has introduced iron pellets, cast iron, steel alloys, and other materials and technologies to the markets of Finland, other Nordic countries, and the rest of Europe.',
            phrase_2: 'We continue to trade in industrial products. Our main focus is on steel products of European, Turkish, and Central Asian origin. Our largest customers are located in Finland, the Baltic States, Poland, Germany, and elsewhere in the EU. Our customers include steel service centers, machine shops, pipe factories, and engineering companies.',
            
            header_2: "Why choose our services and products?",

            small_header_1: "Low overheads: ",
            next_1: 'We always have low overheads compared to many companies in the industry because our small but highly efficient sales team is quick to respond to market conditions and price developments. We also do not sit on a large inventory waiting for better times!',
            
            small_header_2: "Low margins: ",
            next_2: " For us, steel trading is a volume business, and good sales volumes are more important to us than high profit margins!",

            small_header_3: "Market price: ",
            next_3: "We give you the opportunity to focus on your core competencies because our sales prices are always based on market prices obtained from the factories!",
            
            small_header_4: "Direct factory deliveries: ",
            next_4: "Goods are delivered directly from the factory to the customer, eliminating unnecessary intermediaries and intermediate stocks! If the steel requires, for example, cutting, slitting or other processing before final delivery to the customer, we can render such services using steel service centers in Central and Eastern EU. For fast delivery, we can offer steel from our warehouses in Finland and Estonia.",
            
            small_header_6: "Fast door-to-door deliveries ",
            next_6: "– shipments from the factory directly to your warehouse without transshipment or other intermediate measures – by rail, road, and sea."
        },
        fi: {
            header_1: 'Oy Listech Ltd',
            phrase_1: 'Oy Listech Ltd on perustettu vuonna 1986 Helsinkiin. Melkein 40 vuoden matkaamme on mahtunut monenlaisia projektejä mukaanlukien teknologian siirot, tehtaiden peruskorjaukset, uusien tuotteiden ja kansainvälisten suhteiden kehitys Suomessa ja EU:ssa. Näinä vuosina yrityksemme on tuonut Suomen ja muiden Pohjoismaiden sekä muun Euroopan markkinoille rautapellettejä, valurauta harkkoja, terästä, rautaseoksia sekä muita materiaaleja ja teknologioita.',
            phrase_2: 'Perinne jatkuu ja käymme edelleen kauppaa teollisilla tuotteilla. Pääpaino on eurooppalaista, turkkilaista, sekä keski-Aasian alkuperää olevilla terästuotteilla. Suurimmat asiakkaamme ovat Suomessa, Baltiassa, Puolassa, Saksassa sekä muualla EU:ssa. Asiakkaamme ovat teräspalvelukeskukset, konepajat sekä putkitehtaat.',

            header_2: 'Miksi valita juuri meidän tarjoamia palveluja ja tuotteita?',
            
            small_header_1: "Pienet kulut: ",
            next_1: 'Meillä on aina pienet kulut verrattuna moniin alalla toimiviin yrityksiin, sillä pieni mutta erittäin tehokas myyntitiimimme on nopea reagoimaan markkinatilanteisiin ja hintakehitykseen. Emme myöskään istu ison varaston päällä odottamassa parempia aikoja!',
            
            small_header_2: "Pienet katteet: ",
            next_2: 'Meille teräskauppa on volyymi-businestä ja hyvät myyntiluvut ovat meille tärkeämmät kuin isot myyntikatteet!',

            small_header_3: "Markkinahinta: ",
            next_3: 'Annamme teille mahdollisuuden keskittyä omaan ydinosaamiseen, sillä myyntihintamme perustuvat aina tehtailta saatuihin markkinahintoihin!',
            
            small_header_4: "Suorat tehdastoimitukset: ",
            next_4: 'Tavara kulkee asiakkaalle suoraan tehdastoimituksena, mikä poistaa turhat välikädet ja välivarastot kuviosta! Mikäli teräs kaipaa esim. arkitusta tai muun kaltaista työstämistä ennen lopputoimitusta asiakkaalle apuun tulevat keski- ja itä-EU:n teräspalvelukeskukset josta saamme omille materiaalille palveluna esim. teräskelan peittausta ja arkitusta. Nopeaan toimitukseen pystymme tarjoamaan terästä varastostamme Suomesta sekä Virosta.',
            
            small_header_6: "Nopea toimitus ovelta-ovelle ",
            next_6: "– toimitukset tehtaalta suoraan varastoonne ilman siirtokuormausta tai muita välitoimenpiteitä – rautateitse, autoilla ja meriteitse."
        
        }
    };

    return (
        <Container className="container">
            <Row className="align-items-center d-flex justify-content-center">
                <Col  xs={11} sm={11} md={5} className="mb-3">
                    <CarouselNews />
                </Col>
                <Col   xs={11} sm={11} md={7} className="mb-3">
                    <h4 className="mb-3">{menuTitles[language].header_1}</h4>
                    <p className="mb-3">{menuTitles[language].phrase_1}</p>
                    <p className="mb-3">{menuTitles[language].phrase_2}</p>
                </Col>
                <Col   xs={11} sm={11} md={12} className="mb-3">
                    <h4 className="mb-3">{menuTitles[language].header_2}</h4>
                    <p className="mb-3"><span style={{ fontWeight: 'bold' }}>{menuTitles[language].small_header_1}</span>
                        {menuTitles[language].next_1}
                    </p>
                    <p className="mb-3"><span style={{ fontWeight: 'bold' }}>{menuTitles[language].small_header_2}</span>
                        {menuTitles[language].next_2}
                    </p>
                    <p className="mb-3"><span style={{ fontWeight: 'bold' }}>{menuTitles[language].small_header_3}</span>
                        {menuTitles[language].next_3}
                    </p>
                    <p className="mb-3"><span style={{ fontWeight: 'bold' }}>{menuTitles[language].small_header_4}</span>
                        {menuTitles[language].next_4}
                    </p>
                    <p className="mb-3"><span style={{ fontWeight: 'bold' }}>{menuTitles[language].small_header_6}</span>
                        {menuTitles[language].next_6}
                    </p>
                </Col>
            </Row>
        </Container>
    )
}

export default NewsTwo;
import React, {useContext} from "react";
import LanguageContext from '../components/LanguageContext';
import CarouselBox from "../components/CarouselBox";
import { Container, Card, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import NewsImg1 from "../img/home_news/SmallNews1.jpeg";
import NewsImg2 from "../img/home_news/SmallNews2.jpeg";
import NewsImg3 from "../img/home_news/SmallNews3.jpeg";

const Home = ({ children }) => {
    const { language } = useContext(LanguageContext);

    const menuTitles = {
        en: {
            header_new_1: 'Scope of Supply',
            header_new_2: 'Safe and reliable partner',
            header_new_3: 'Fast delivery',
            new_1: 'Our main products are hot and cold rolled coils, sheets, and strips, as well as coated, hot-dip galvanized, and pre-painted steel sheets. In addition, we offer heavy plates, structural hollow sections, seamless pipes in various steel grades, and for various purposes in the construction industry and machine shops.',
            new_2: 'Durable and long-term relationships with steel mills and customers ensure a competitive and reliable operating model. We navigate smoothly and flexibly in rapidly evolving international markets and offer our customers alternative material flows and comprehensive steel solutions.',
            new_3: 'We offer door-to-door transportations directly from the mill straight to your warehouse without re-loading and unnecessary middle transactions on time by railway, trucks or sea.'
        },
        fi: {
            header_new_1: 'Laaja valikoima',
            header_new_2: 'Turvallinen ja luotettava',
            header_new_3: 'Nopea toimitus',
            new_1: 'Päätuotteitamme ovat kuuma- ja kylmävalssatut kelat, levyt ja rainat sekä myös peitatut, kuumasinkityt ja maalipinnoitetut teräkset. Lisäksi meiltä saa kvarttolevyä, rakenneputkea, saumatonta aines- ja rakenneputkea monissa teräslaadussa ja moniin tarkoituksiin rakennusteollisuudelle sekä konepajoille.',
            new_2: 'Kestävät ja pitkäaikaiset suhteet terästehtaisiin ja asiakkaisiin takaavat kilpailukykyisen ja luotettavan toimintamallin. Liikumme sujuvasti ja joustavasti nopeasti kehittyvilläkansainvälisillä markkinoilla ja tarjoamme asiakkaillemme vaihtoehtoisia materiaalivirtoja ja kokonaisvaltaisia teräsratkaisuja.',
            new_3: 'Ovelta-ovelle -toimitukset tehtaalta suoraan varastoonne ilman siirtokuormausta tai muita välitoimenpiteitä – rautateitse, autoilla ja meriteitse.'
        }
    };

    return (
        <>
            <CarouselBox />
            <Container className="container">
                <Row className="d-flex justify-content-center mt-5">
                    <Col xs={11} md={4} className="mb-5">
                        <NavLink to="/our_products" className="text-decoration-none">
                            <Card bg="light" className="card h-100">
                                <Card.Body>
                                    <Card.Title className="text-center">{menuTitles[language].header_new_1}</Card.Title>
                                    <Card.Text className="aboutText"><br/>{menuTitles[language].new_1}</Card.Text>
                                </Card.Body>
                                <Card.Img variant="top" src={NewsImg1} />
                            </Card>
                        </NavLink>
                    </Col>
                    <Col xs={11} md={4} className="mb-5">
                        <NavLink to="/partners" className="text-decoration-none">
                            <Card bg="light" className="card h-100">
                                <Card.Body>
                                    <Card.Title className="text-center">{menuTitles[language].header_new_2}</Card.Title>
                                    <Card.Text className="aboutText"><br/>{menuTitles[language].new_2}</Card.Text>
                                </Card.Body>
                                <Card.Img variant="top" src={NewsImg2} />
                            </Card>
                        </NavLink>
                    </Col>
                    <Col xs={11} md={4} className="mb-5">
                        <NavLink to="/about" className="text-decoration-none">
                            <Card bg="light" className="card h-100">
                                <Card.Body>
                                    <Card.Title className="text-center">{menuTitles[language].header_new_3}</Card.Title>
                                    <Card.Text className="aboutText"><br/>{menuTitles[language].new_3}</Card.Text>
                                </Card.Body>
                                <Card.Img className="img" variant="top" src={NewsImg3} />
                            </Card>
                        </NavLink>
                    </Col>
                </Row>                    
            </Container>
        </>
    );
};

export default Home;
import React, {useContext} from "react";
import LanguageContext from '../components/LanguageContext';
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import CarouselNews from "../components/CarouselNews.js";

const NewsOne = ({ children }) => {
    const { language } = useContext(LanguageContext);

    const menuTitles = {
        en: {
            header_1: 'Steel products',
            phrase_1: 'The strengths of Oy Listech Ltd are:',
            small_header: ['Sheets and Coils:', 'Long Steel Products:'],
            description: ['First-class hot and cold rolled steel coils, sheets, and strips, as well as heavy plates, coated steels, including hot-dip galvanized coils, sheets, and strips, and pre-painted materials.', 'Structural hollow sections, seamless pipes, spirally welded pipes, furniture tubes, wire rod, low carbon wire, spring wire, welding wire, steel wire ropes, cast iron ingots, and round bars, including forged ones.'],
            header_2: 'Other steel products',
            phrase_3: 'We also supply the following steels:',
            list: ['Billets and slabs', 'Reinforcing steels', 'UNP beams', 'UPE beams', 'INP beams', 'IPE beams', 'HEA beams', 'HEB beams', 'Quenched and tempered steels', 'Flat bars', 'Square bars', 'Angle bars', 'Precision tubes - round, square, and rectangular', 'Cold-formed U-profiles'],
            appeal_1: 'Please do not hesitate to ask us for a quote for these and other steel materials. You can submit your inquiries on our ',
            appeal_2: 'contacts page.'
        },
        fi: {
            header_1: 'Terästuotteet',
            phrase_1: 'Oy Listech Ltd:n vahvuudet ovat:',
            small_header: ['Levyt ja kelat:', 'Pitkät teräkset:'],
            description: ['Ensiluokkaiset kuuma- ja kylmävalssatut teräskelat, -levyt ja -rainat, sekä kvarttolevyt, ja peitatut ja pinnoitetut teräkset, myöskin kuumasinkityt kelat, levyt ja rainat ja maalipinnoitetut materiaalit.', 'Putkipalkit, ainesputket, kierresaumatut putket,huonekaluputket, valssilanka, matalahiililanka, jousilanka, hitsauslanka, teräsvaijerit, valurautaharkot sekä pyörötangot, myös taotut.'],
            header_2: 'Muut terästuotteet',
            phrase_3: 'Toimitamme myös seuraavia teräksiä:',
            list: ['Aihiot ja slabit', 'Harjateräkset', 'UNP palkit', 'UPE palkit', 'INP palkit', 'IPE palkit', 'HEA palkit', 'HEB palkit', 'Nuorrutusteräkset', 'Lattateräkset', 'Neliöteräkset', 'Kulmateräkset', 'Tarkkuusputket - pyöreät, neliöt ja suorakaiteet', 'Kylmämuovatut U-profiilit'],
            appeal_1: 'Mikäli tarvetta löytyy muille teräsmateriaaleille, ota yhteyttä myyntiimme. Voit lähettää kyselysi ',
            appeal_2: 'yhteydenottosivulta.'
        }
    };

    return (
        <Container className="container">
            <Row className="align-items-center d-flex justify-content-center">
                <Col  xs={11} sm={11} md={5} className="mb-3">
                    <CarouselNews />
                </Col>
                <Col   xs={11} sm={11} md={7} className="mb-3">
                        <h4 className="mb-3">{menuTitles[language].header_1}</h4>
                        <p className="mb-3">{menuTitles[language].phrase_1}</p>
                        <p style={{ fontStyle: 'italic' }}>{menuTitles[language].small_header[0]}</p>
                        <p className="mb-3">{menuTitles[language].description[0]}</p>
                        <p style={{ fontStyle: 'italic' }}>{menuTitles[language].small_header[1]}</p>
                        <p className="mb-3">{menuTitles[language].description[1]}</p>
                </Col>
                <Col   xs={11} sm={11} md={12} className="mb-3">
                        <h4 className="mb-3">{menuTitles[language].header_2}</h4>
                        <p className="mb-3">{menuTitles[language].phrase_3}</p>
                        <Row>
                            <Col xs={6} md={3} className="mb-3">
                                <p style={{ fontStyle: 'italic' }}>
                                    {menuTitles[language].list[0]}
                                    <br/>{menuTitles[language].list[1]}
                                    <br/>{menuTitles[language].list[2]}
                                    <br/>{menuTitles[language].list[3]}
                                </p>
                            </Col>
                            <Col xs={6} md={3} className="mb-3">
                                <p style={{ fontStyle: 'italic' }}>
                                    {menuTitles[language].list[4]}
                                    <br/>{menuTitles[language].list[5]}
                                    <br/>{menuTitles[language].list[6]}
                                    <br/>{menuTitles[language].list[7]}
                                </p>
                            </Col>
                            <Col xs={6} md={3} className="mb-3">
                                <p style={{ fontStyle: 'italic' }}>
                                    {menuTitles[language].list[8]}
                                    <br/>{menuTitles[language].list[9]}
                                    <br/>{menuTitles[language].list[10]}
                                    <br/>{menuTitles[language].list[11]}
                                </p>
                            </Col>
                            <Col xs={6} md={3} className="mb-3">
                                <p style={{ fontStyle: 'italic' }}>
                                    {menuTitles[language].list[12]}
                                    <br/>{menuTitles[language].list[13]}
                                </p>
                            </Col>
                        </Row>
                        <p  style={{ fontWeight: 'bold' }}><br/>{menuTitles[language].appeal_1}<NavLink to="/contacts_us">{menuTitles[language].appeal_2}</NavLink></p>
                </Col>
            </Row>
        </Container>
    )
}

export default NewsOne;
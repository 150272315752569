import React, {useContext} from "react";
import LanguageContext from '../../components/LanguageContext';
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import CarouselBioFlues from "../../components/CarouselBioFlues.js";

const BioFuels = ({ children }) => {
  const { language } = useContext(LanguageContext);

  const menuTitles = {
    en: {
      header: "Bio-fuels, charcoal and bio-char", 
      phrase_1: "We import and sell various bio-fuels from Central and Eastern EU markets. Our wide network of producers ensures regular truck deliveries throughout the year for industrial and household use. For industrial/volume deliveries we offer vessel shipments to Finland and Scandinavia in bags or in bulk.",
      phrase_2: "We deliver:",
      list: ["A1 wooden pellets 100% pine.", "A2 for industrial and household use.", "We can offer a mix of different raw materials to ensure the customers’ needs, such as oak, pine, sunflower seed, and corn processing industry bi-product mix.", "Wooden briquettes.", "Charcoal for household and industrial use.", "Bio-char.", "Bio-ethanol."],
      phrase_3: "For other grades and details, please contact our sales team. You can submit your inquiries on our ",
      next_3: "contacts page."
    },
    fi: {
      header: "Biopolttoaineet, puu- ja biohiili",
      phrase_1: "Tuomme maahan erilaisia biopolttoaineita Keski- ja Itä-EU:n markkinoilta. Laaja toimittajaverkostomme takaa säännöliset rekkatoimitukset ympäri vuoden sekä teollisuuskäyttöön että kotitalouksille. Teollisuuskäyttöön/ isompiin tarpeisiin tarjoamme yksittäiset tai säännölliset laivaukset Suomen ja Pohjoismaiden satamiin sekä säkitettynä että bulkkina.",
      phrase_2: "Toimitamme mm:",
      list: ["A1 puupelletti 100% havupuusta.", "A2 koti- ja teollisuuskäyttöön.", "Pystymme räätälöimään pellettiseokset asiakastarpeisiin erilaisista raaka-aineista kuten esim. tammi, havu, auringonkukkasiemen, maissiteollisuuden sivuvirta.", "Puubriketit.", "Puuhiili koti- ja teollisuuskäyttöön", "Biohiili", "Bioetanoli"],
      phrase_3: "Mikäli tarvitsette muita teräslaatuja ja/tai kokoja, ota yhteyttä myyntiimme. Voit lähettää kyselysi ",
      next_3: "yhteydenottosivulta."
    }
  };
  
  return (
    <Container className="container">
        <Row className="align-items-center d-flex justify-content-center">
            <Col  xs={11} sm={11} md={5} className="mb-3">
                <CarouselBioFlues />
            </Col>
            <Col xs={11} sm={11} md={7} className="mt-3">
                <h2>{menuTitles[language].header}</h2>
                <p className="mt-3">{menuTitles[language].phrase_1}</p>
                <p className="mt-3">{menuTitles[language].phrase_2}</p>
                <ul>
                    <li>{menuTitles[language].list[0]}</li>
                    <li>{menuTitles[language].list[1]}</li>
                    <li>{menuTitles[language].list[2]}</li>
                    <li>{menuTitles[language].list[3]}</li>
                    <li>{menuTitles[language].list[4]}</li>
                    <li>{menuTitles[language].list[5]}</li>
                    <li>{menuTitles[language].list[6]}</li>
                </ul>
            </Col>
            <Col xs={11} sm={11} md={11} className="mb-5">
                <p className="mt-3 mb-3">{menuTitles[language].phrase_3}<NavLink to="/contacts_us">{menuTitles[language].next_3}</NavLink></p>
            </Col>
        </Row>
    </Container>
        )
    }

export default BioFuels;

import React, { useEffect } from 'react';
import { HashRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { LanguageProvider } from './components/LanguageContext';
import Header from './components/Header';
import Bottom from "./components/Bottom";
import Home from './pages/Home.js';
import NewsOne from './pages/NewsOne';
import NewsTwo from './pages/NewsTwo';
import About from './pages/About.js';
import Products from './pages/Products.js';
import HotRolledStripSteels from './pages/products/HotRolledStripSteels.js';
import ColdRolledFlatSteel from './pages/products/ColdRolledFlatSteel.js';
import HotDipGalvanizedSteel from './pages/products/HotDipGalvanizedSteel.js';
import QuatroPlates from './pages/products/QuatroPlates.js';
import StructuralHollowSections from './pages/products/StructuralHollowSections.js';
import HelicallyWeldedPipesSAWH from './pages/products/HelicallyWeldedPipesSAWH.js';
import PrePaintedSteelMaterial from './pages/products/Pre-paintedSteelMaterial.js';
import OtherSteelProducts from './pages/products/OtherSteelProducts.js';
import SteelPreManufacturingServices from './pages/products/SteelPre-manufacturingServices.js'
import BioFuels from './pages/products/BioFluels.js'
import ContactUs from './pages/ContactUs.js';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};


function App() {
  return (
    <LanguageProvider>
      <div className='body'>
        <Header />
        <Router>
            <ScrollToTop />
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/about" element={<About />} />
              <Route exact path="/our_products" element={<NewsOne />} />
              <Route exact path="/partners" element={<NewsTwo />} />
              <Route exact path="/products" element={<Products />} />
              <Route exact path="/products/hot-rolled_strip_steels" element={<HotRolledStripSteels />} />
              <Route exact path="/products/cold_rolled_flat_steel" element={<ColdRolledFlatSteel />} />
              <Route exact path="/products/hot_dip_galvanized_steel" element={<HotDipGalvanizedSteel />} />
              <Route exact path="/products/quatro_plates" element={<QuatroPlates />} />
              <Route exact path="/products/structural_hollow_sections" element={<StructuralHollowSections />} />
              <Route exact path="/products/helically_welded_pipes_SAWH" element={<HelicallyWeldedPipesSAWH />} />
              <Route exact path="/products/pre-painted_steel_material" element={<PrePaintedSteelMaterial />} />
              <Route exact path="/products/other_steel_products" element={<OtherSteelProducts />} />
              <Route exact path="/products/steel_pre-manufacturing_services" element={<SteelPreManufacturingServices />} />
              <Route exact path="/products/bio-fuels,charcaol_and_bio-char" element={<BioFuels />} />
              <Route exact path="/contacts_us" element={<ContactUs />} />
            </Routes>
        </Router>
        <Bottom/>
      </div>
    </LanguageProvider>
  );
}

export default App;

import React, {useContext} from "react";
import LanguageContext from '../../components/LanguageContext';

const Table = ({ children }) => {
  const { language } = useContext(LanguageContext);

  const menuTitles = {
    en: {
      thead_1: "Steel Grade",
      thead_2: "Width, up to, mm",
      thead_3: "Thickness range, mm",
      thead_4: "Manufacturing Standard"

    },
    fi: {
      thead_1: "Teräslaatu",
      thead_2: "Leveys, max., mm",
      thead_3: "Paksuus, välillä, mm",
      thead_4: "Tuotestandardi"
    }
  };
  
  return (
    <div style={{ width: '100%', overflowX: 'auto' }}>
      <table  className="table table-bordered">
        <thead>
          <tr>
            <th scope="col">{menuTitles[language].thead_1}</th>
            <th scope="col">{menuTitles[language].thead_2}</th>
            <th scope="col">{menuTitles[language].thead_3}</th>
            <th scope="col">{menuTitles[language].thead_4}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>DC 01</td><td>1500</td><td>0,30 - 3,20</td><td>EN 10130</td>
          </tr>
          <tr>
            <td>DC 02</td><td>1500</td><td>0,30 - 3,20</td><td>EN 10130</td>
          </tr>
          <tr>
            <td>DC 03</td><td>1500</td><td>0,30 - 3,20</td><td>EN 10130</td>
          </tr>
          <tr>
            <td>DC 04</td><td>1500</td><td>0,30 - 3,20</td><td>EN 10130</td>
          </tr>
          <tr>
            <td>DC 05</td><td>1500</td><td>0,30 - 3,20</td><td>EN 10130</td>
          </tr>
          <tr>
            <td>DC 06</td><td>1500</td><td>0,30 - 3,20</td><td>EN 10130</td>
          </tr>
          <tr>
            <td>H 240 LA</td><td>1500</td><td>0,30 - 3,20</td><td>EN 10268</td>
          </tr>
          <tr>
            <td>H 280 LA</td><td>1500</td><td>0,30 - 3,20</td><td>EN 10268</td>
          </tr>
          <tr>
            <td>H 320 LA</td><td>1500</td><td>0,30 - 3,20</td><td>EN 10268</td>
          </tr>
          <tr>
            <td>H 360 LA</td><td>1500</td><td>0,30 - 3,20</td><td>EN 10268</td>
          </tr>
          <tr>
            <td>H 400 LA</td><td>1500</td><td>0,30 - 3,20</td><td>EN 10268</td>
          </tr>
          <tr>
            <td>HC 260 LA</td><td>1500</td><td>0,30 - 3,20</td><td>EN 10268</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default Table;
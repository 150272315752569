import React, { useContext, useState } from 'react';
import LanguageContext from './LanguageContext';
import { Navbar, Container, Nav } from "react-bootstrap"; 
import logo from "../img/logo.png";
import ukFlag from '../img/flags/UnitedKingdom.png';
import finlandFlag from '../img/flags/Finland.png';
import { FaTimes } from 'react-icons/fa';

const Header = ({ children }) => {
    const { language, toggleLanguage } = useContext(LanguageContext);
    const [menuOpen, setMenuOpen] = useState(false);

    const getFlagImage = () => {
        return language === 'fi' ? ukFlag : finlandFlag;
    };
    
    const menuTitles = {
        en: {
            about: 'About',
            products: 'Products',
            contact_us: 'Contact us'
        },
        fi: {
            about: 'Tietoa Meistä',
            products: 'Tuotteet',
            contact_us: 'Yhteys'
        }
    };

    const handleLinkClick = () => {
        setMenuOpen(false);
    };

    const handleToggleLanguage = () => {
        toggleLanguage();
    };

    return (
        <>
            <Navbar fixed="top" collapseOnSelect expand="md" bg="light" variant="light" className="justify-content-end bg-light-grey">
                <Container fluid>
                    <Navbar.Brand href="/">
                        <img
                            src={logo}
                            height="38"
                            width="120"
                            className="d-inline-block align-top"
                            alt="Logo"
                        />
                    </Navbar.Brand>
                    <Nav.Item style={{ color: 'black', margin: '0 10px', fontSize: 'x-small' }}>
                        📞 +358-(0)9-228-91-10<br />
                        ✉️ <a href="mailto:listech@listech.fi" className="text-decoration-none">listech@listech.fi</a>
                    </Nav.Item>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setMenuOpen(!menuOpen)}>
                        {menuOpen ? <FaTimes /> : <span>&#9776;</span>}
                    </Navbar.Toggle>
                    <Navbar.Collapse id="responsive-navbar-nav" className={menuOpen ? 'show' : ''}>
                        <div className="flex-grow-1"></div>
                        <Nav>
                            <Nav.Link href="/#/about" style={{ color: 'black', margin: '0 10px' }} onClick={handleLinkClick}>
                                {menuTitles[language].about}
                            </Nav.Link>
                            <Nav.Link href="/#/products" style={{ color: 'black', margin: '0 10px' }} onClick={handleLinkClick}>
                                {menuTitles[language].products}
                            </Nav.Link>
                            <Nav.Link href="/#/contacts_us" style={{ color: 'black', margin: '0 10px' }} onClick={handleLinkClick}>
                                {menuTitles[language].contact_us}
                            </Nav.Link>
                            <Nav.Item onClick={handleToggleLanguage} style={{ cursor: 'pointer' }}>
                                <img
                                    src={getFlagImage()}
                                    height="24"
                                    width="36"
                                    alt={language === 'fi' ? "UK Flag" : "Finnish Flag"}
                                    style={{ margin: '0 10px' }} 
                                />
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div style={{ marginTop: '10vh' }}>
                {children}
            </div>
        </>
    );
};

export default Header;
import React, {useContext} from "react";
import LanguageContext from '../../components/LanguageContext';

const Table = ({ children }) => {
  const { language } = useContext(LanguageContext);

  const menuTitles = {
    en: {
      thead_1: "Steel Grade",
      thead_2: "Thickness range, mm",
      thead_3: "Width, up to, mm",
      thead_4: "Manufacturing Standard",
      thead_5: "Note"

    },
    fi: {
      thead_1: "Teräslaatu",
      thead_2: "Paksuus, välillä, mm",
      thead_3: "Leveys, max., mm",
      thead_4: "Tuotestandardi",
      thead_5: "Huom."
    }
  };
  
  return (
    <div style={{ width: '100%', overflowX: 'auto' }}>
      <table  className="table table-bordered">
        <thead>
          <tr>
            <th scope="col">{menuTitles[language].thead_1}</th>
            <th scope="col">{menuTitles[language].thead_2}</th>
            <th scope="col">{menuTitles[language].thead_3}</th>
            <th scope="col">{menuTitles[language].thead_4}</th>
            <th scope="col">{menuTitles[language].thead_5}</th>
          </tr>
        </thead>
        <tbody>
          <tr><td>S235JR, J2+N</td><td>6-200</td><td>3000</td><td>EN 10025</td><td>Flatness S on demand</td></tr>
          <tr><td>S275JR, J2+N</td><td>6-200</td><td>3000</td><td>EN 10025</td><td>Flatness S on demand</td></tr>
          <tr><td>S355JR, J2, K2+N</td><td>6-200</td><td>3000</td><td>EN 10025</td><td>Flatness S on demand</td></tr>
          <tr><td>S355ML</td><td>6-200</td><td>3000</td><td>EN 10113 / 10025</td><td>Flatness S on demand</td></tr>
          <tr><td>S355N</td><td>6-200</td><td>3000</td><td>EN 10113</td><td>Flatness S on demand</td></tr>
          <tr><td>P355NL1</td><td>6-200</td><td>3000</td><td>EN 10028</td><td>Flatness S on demand</td></tr>
          <tr><td>P265GH</td><td>6-200</td><td>3000</td><td>EN 10028</td><td>Flatness S on demand</td></tr>
          <tr><td>P460NL1</td><td>6-200</td><td>3000</td><td>EN 10028</td><td>Flatness S on demand</td></tr>
          <tr><td>S420M/N</td><td>6-200</td><td>3000</td><td>EN 10025</td><td>Flatness S on demand</td></tr>
          <tr><td>S460M/N</td><td>6-200</td><td>3000</td><td>EN 10025</td><td>Flatness S on demand</td></tr>
        </tbody>
      </table>
    </div>
  )
}

export default Table;
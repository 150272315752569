import React, {useContext} from "react";
import LanguageContext from '../../components/LanguageContext';
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const SteelPreManufacturingServices = ({ children }) => {
    const { language } = useContext(LanguageContext);

    const menuTitles = {
        en: {
          header_1: 'Steel pre-manufacturing services',
          phrase_1: 'Oy Listech Ltd provides on-demand steel pre-treatment services through a network of partners, including:',
          list_1: ['Plasma cutting', 'Flame cutting', 'Welding', 'Driling', 'Prime-paiting'],
          phrase_2: 'We also manufacture various products according to customer specifications and drawings, as well as those developed through our own design. We have supplied the following to the Nordic countries:',
          list_2: ['Custom-cut and/or otherwise pre-treated and primed steel plates (e.g., elevator counterweights)', 'Welded steel structures with or without primer painting (e.g., excavator buckets and arms, piling machine parts, and truck beds)', 'Concrete mixer drum barrels and other parts and equipment for concrete transport vehicles painted to the required colors'],
          phrase_3: "For other grades and details, please contact our sales team. You can submit your inquiries on our ",
          next_3: "contacts page."
        },
        fi: {
            header_1: 'Teräksen esikäsittelypalvelut',
            phrase_1: 'Oy Listech Ltd toimittaa tilauksesta kumppaniverkoston teräksen esikäsittelypalveluita, kuten esim.:',
            list_1: ['Plasma leikkaus', 'Polttoleikkaus', 'Hitsaus', 'Poraus', 'Pohjamaalaus'],
            phrase_2: 'Valmistamme myös asiakkaan omien mittojen ja piirustusten mukaisia sekä oman suunnittelumme kehittämiä erilaisia tuotteita. Olemme toimittaneet Pohjoismaihin mm. seuraavaa:',
            list_2: [' Määrämittaan leikattuja ja/tai muuten esikäsiteltyjä ja pohjamaalattuja teräslevyjä (esim. hissien vastapainot)', 'Hitsattuja teräsrakenteita pohjamaalauksella ja ilman (esim. kaivinkoneiden kauhat ja varret, paalutuskoneiden osat sekä kuormurien lavat)', 'Betonin pyörintäsäiliöautojen rumpuja sekä betonin kuljetuskaluston muita osia ja laitteita pohjamaalattuna'],
            phrase_3: "Mikäli tarvitsette muita teräslaatuja ja/tai kokoja, ota yhteyttä myyntiimme. Voit lähettää kyselysi ",
            next_3: "yhteydenottosivulta."
        }
    };

    return (
        <Container className="container">
            <Row className="align-items-center d-flex justify-content-center">
                <Col xs={11} sm={11} md={11} className="mt-3">
                    <h2>{menuTitles[language].header_1}</h2>
                    <p className="mt-3">{menuTitles[language].phrase_1}</p>
                    <ul>
                      <li>{menuTitles[language].list_1[0]}</li>
                      <li>{menuTitles[language].list_1[1]}</li>
                      <li>{menuTitles[language].list_1[2]}</li>
                      <li>{menuTitles[language].list_1[3]}</li>
                      <li>{menuTitles[language].list_1[4]}</li>
                    </ul>
                    <p className="mt-3">{menuTitles[language].phrase_2}</p>
                    <ul>
                      <li>{menuTitles[language].list_2[0]}</li>
                      <li>{menuTitles[language].list_2[1]}</li>
                      <li>{menuTitles[language].list_2[2]}</li>
                    </ul>
                </Col>
                <Col xs={11} sm={11} md={11} className="mb-5">
                    <p className="mt-3 mb-3">{menuTitles[language].phrase_3}<NavLink to="/contacts_us">{menuTitles[language].next_3}</NavLink></p>
                </Col>
            </Row>
        </Container>
    )
}

export default SteelPreManufacturingServices;


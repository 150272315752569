import React, {useContext} from "react";
import LanguageContext from '../../components/LanguageContext';
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Table from "./TableColdRolledFlatSteel";

const ColdRollerFlatSteel = ({ children }) => {
  const { language } = useContext(LanguageContext);

  const menuTitles = {
    en: {
      header: "Cold Rolled Steel",
      phrase_1: "High-quality steel according to EN standards.",
      phrase_2: "NEW! Sheets and strips directly from EU steel service centers to your stock with trucks anywhere in Finland and elsewhere in the Nordic countries and Europe. High sheet quality and good material availability. Steels of EU origin.",
      phrase_3: "Cold-rolled products:",
      list: ["Coils, including small coils", "Strips", "Sheets"],
      phrase_4: "The products always come with a material certificate 3.1. according to EN 10204, CE marking. Sheets are hot-dip galvanizable.",
      phrase_5: "Below is a list of available steel grades and dimensions.",
      phrase_6: "For other grades and details, please contact our sales team. You can submit your inquiries on our ",
      next_6: "contacts page."
    },
    fi: {
      header: "Kylmävalssatut teräkset",
      phrase_1: "Korkealaatuista terästä EN-standardien mukaan.",
      phrase_2: "Valmiiksi arkitettua levyä ja rainaa suoraan EU:n teräspalvelukeskuksista varastoonne autoilla minne tahansa Suomeen ja muualle Pohjoismaihin ja Eurooppaan. Korkea arkituslaatu ja hyvä materiaalisaatavuus. Teräkset EU:n alkuperää.",
      phrase_3: "Kylmävalssatut tuotteet:",
      list: ["Kelat, myös pienkelat", "Rainat", "Levyt"],
      phrase_4: "Tuotteilla on aina ainestodistus 3.1. EN 10204 mukaan, CE-merkintä. Levyt ovat kuumasinkittäviä.",
      phrase_5: "Alla lista saatavilla olevista teräslaaduista ja mitoista.",
      phrase_6: "Mikäli tarvitsette muita teräslaatuja ja/tai kokoja, ota yhteyttä myyntiimme. Voit lähettää kyselysi ",
      next_6: "yhteydenottosivulta."
    }
  };
  
  return (
    <Container className="container">
      <Row className="align-items-center d-flex justify-content-center">
        <Col xs={11} sm={11} md={11} className="mt-3">
          <h2>{menuTitles[language].header}</h2>
          <p className="mt-3">{menuTitles[language].phrase_1}</p>
          <p className="mt-3">{menuTitles[language].phrase_2}</p>
          <p className="mt-3">{menuTitles[language].phrase_3}</p>
          <ul>
            <li>{menuTitles[language].list[0]}</li>
            <li>{menuTitles[language].list[1]}</li>
            <li>{menuTitles[language].list[2]}</li>
          </ul>
          <p className="mt-3">{menuTitles[language].phrase_4}</p>
          <p className="mt-3 mb-3">{menuTitles[language].phrase_5}</p>
        </Col>
        <Col xs={11} sm={11} md={11} className="mt-3">
          <Table />
        </Col>
        <Col xs={11} sm={11} md={11} className="mb-5">
          <p className="mt-3 mb-3">{menuTitles[language].phrase_6}<NavLink to="/contacts_us">{menuTitles[language].next_6}</NavLink></p>
        </Col>
      </Row>
    </Container>
        )
    }

export default ColdRollerFlatSteel;

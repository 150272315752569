import React, {useContext} from "react";
import LanguageContext from '../../components/LanguageContext';
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Table from "./TableHotDipGalvanizedSteel";

const HotDipGalvanizedSteel = ({ children }) => {
    const { language } = useContext(LanguageContext);
  
    const menuTitles = {
      en: {
        header: "Hot-Dip Galvanized Steel",
        phrase_1: "High-quality steel according to EN standards.",
        phrase_2: "Sheets and strips directly from EU steel service centers to your stock with trucks anywhere in Finland and elsewhere in the Nordic countries and Europe. High sheet quality and good material availability. Steels of EU origin.",
        phrase_3: "We supply structural steel, high-strength steel, and steel suitable for cold forming:",
        list: ["Coils, including small coils", "Strips", "Sheets"],
        phrase_4: "Below is a list of available steel grades and dimensions.",
        phrase_5: "The products always come with a material certificate 3.1. according to EN 10204, CE marking.",
        phrase_6: "With RoHS Cr3+ passivation or Cr6+ passivation.",
        phrase_7: "For other grades and details, please contact our sales team. You can submit your inquiries on our ",
        next_7: "contacts page."
      },
      fi: {
        header: "Kuumasinkityt teräkset",
        phrase_1: "Korkealaatuista terästä EN-standardien mukaan.",
        phrase_2: "Valmiiksi arkitettua levyä ja rainaa suoraan EU:n teräspalvelukeskuksista varastoonne autoilla minne tahansa Suomeen ja muualle Pohjoismaihin ja Eurooppaan. Korkea arkituslaatu ja hyvä materiaalisaatavuus. Teräkset EU:n alkuperää.",
        phrase_3: "Toimitamme rakenneterästä, erikoislujaa terästä sekä kylmämuovaamiseen soveltuvaa terästä:",
        list: ["Kelana, myös pienkelana", "Rainana", "Levynä"],
        phrase_4: "Alla lista saatavilla olevista teräslaaduista ja mitoista.",
        phrase_5: "Tuotteilla on aina ainestodistus 3.1. EN 10204 mukaan, CE-merkintä.",
        phrase_6: "RoHS Cr3+ passivoinnilla tai Cr6+ passivoinnilla.",
        phrase_7: "Mikäli tarvitsette muita teräslaatuja ja/tai kokoja, ota yhteyttä myyntiimme. Voit lähettää kyselysi ",
        next_7: "yhteydenottosivulta."
      }
    };
        return (
        <Container className="container">
            <Row className="align-items-center d-flex justify-content-center">
              <Col xs={11} sm={11} md={11} className="mt-3">
                <h2>{menuTitles[language].header}</h2>
                <p className="mt-3">{menuTitles[language].phrase_1}</p>
                <p className="mt-3">{menuTitles[language].phrase_2}</p>
                <p className="mt-3">{menuTitles[language].phrase_3}</p>
                <ul>
                    <li>{menuTitles[language].list[0]}</li>
                    <li>{menuTitles[language].list[1]}</li>
                    <li>{menuTitles[language].list[2]}</li>
                </ul>
                <p className="mt-3">{menuTitles[language].phrase_4}</p>
                <p className="mt-3 mb-3">{menuTitles[language].phrase_5}</p>
                <p className="mt-3 mb-3">{menuTitles[language].phrase_6}</p>
            </Col>
            <Col xs={11} sm={11} md={11} className="mt-3">
                <Table />
            </Col>
            <Col xs={11} sm={11} md={11} className="mb-5">
                <p className="mt-3 mb-3">{menuTitles[language].phrase_7}<NavLink to="/contacts_us">{menuTitles[language].next_7}</NavLink></p>
            </Col>
        </Row>
    </Container>
    )
}

export default HotDipGalvanizedSteel;
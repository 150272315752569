import Carousel from 'react-bootstrap/Carousel';
import React, { useContext } from 'react';
import LanguageContext from './LanguageContext';
import banner_01 from "../img/carousel_img/banner_01.jpg";
import banner_02 from "../img/carousel_img/banner_02.jpg";
import banner_03 from "../img/carousel_img/banner_03.jpg";
import banner_04 from "../img/carousel_img/banner_04.jpg";

const CarouselBox  = ({ children }) => {
    const { language } = useContext(LanguageContext);

    const menuTitles = {
        en: {
            header_1: '',
            header_2: '',
            header_3: '',
            header_4: '',
            text_1: '',
            text_2: '',
            text_3: '',
            text_4: ''
        },
        fi: {
            header_1: '',
            header_2: '',
            header_3: '',
            header_4: '',
            text_1: '',
            text_2: '',
            text_3: '',
            text_4: ''
        }
    };

    return (
        <Carousel>
            <Carousel.Item>
                <img 
                    className="d-block w-100" 
                    src={banner_01} 
                    alt="FirstBanner" 
                />
                <Carousel.Caption>
                    <h3>{menuTitles[language].header_1}</h3>
                    <p>{menuTitles[language].text_1}</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img 
                    className="d-block w-100" 
                    src={banner_02} 
                    alt="SeccondBanner" 
                />
                <Carousel.Caption>
                    <h3>{menuTitles[language].header_2}</h3>
                    <p>{menuTitles[language].text_2}</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100" 
                    src={banner_03} 
                    alt="ThirdBanner" 
                />
                <Carousel.Caption>
                    <h3>{menuTitles[language].header_3}</h3>
                    <p>{menuTitles[language].text_3}</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img 
                    className="d-block w-100" 
                    src={banner_04} 
                    alt="FourthBanner" 
                />
                <Carousel.Caption>
                    <h3>{menuTitles[language].header_4}</h3>
                    <p>{menuTitles[language].text_4}</p>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
};

export default CarouselBox;
